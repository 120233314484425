import i18n from 'i18n/i18n';

import {
  initialLoginError,
  initialRegisterError,
  initialRegisterTelegramError,
  initialPhoneRegError
} from './authSlice';

import type {
  LoginErrors,
  RegisterEmailErrors,
  RegisterPhoneErrors,
  RegisterTelegramErrors,
  PasswordResetVerifyRespons
} from 'types/auth-data';

export const setToken = (token: string) => {
  localStorage.setItem('token', token);
};

export const getToken = (): string | null => localStorage.getItem('token');

export const deleteToken = () => {
  localStorage.removeItem('token');
};

export type ShortError = {
  detail: string;
};

export type LogicError = {
  detail: {
    loc: string[];
    msg: string;
    type: string;
  }[];
};

export type BackendError = ShortError | LogicError;

export const handleLoginErrors = (status: number, data: BackendError): LoginErrors => {
  const { detail } = data;
  const errors: LoginErrors = { ...initialLoginError };

  if (typeof detail === 'string') {
    if (detail === 'Password incorrect') {
      errors.password = i18n.t('backend-errors.auth.password-incorrect', 'Неверный пароль');
    } else if (detail === 'User not found') {
      errors.email = i18n.t('backend-errors.auth.user-not-found', 'Пользователь не найден');
    } else {
      errors.common = detail;
    }
  }

  if (Array.isArray(detail)) {
    detail.forEach((err) => {
      const location = err.loc?.[1];
      const message = err.msg;
      if (location === 'email') {
        errors.email = message;
      } else if (location === 'password') {
        errors.password = message;
      } else {
        errors.common = message;
      }
    });
  }
  return errors;
};

export const handleRestoreErrors = (status: number, data: ShortError): string => data.detail === 'User not found'
  ? i18n.t('backend-errors.auth.user-not-found', 'Пользователь не найден')
  : data.detail;

const errorsTranslates: Record<string, string> = {
  'Invalid name': i18n.t('backend-errors.auth.invalid-name', 'Введите имя и фамилию'),
};

export const handleRegisterErrors = (status: number, data: BackendError): RegisterEmailErrors => {
  const { detail } = data;
  const errors: RegisterEmailErrors = { ...initialRegisterError };

  if (typeof detail === 'string') {
    switch (detail) {
      case 'User exists':
        errors.email = i18n.t('backend-errors.auth.user-exists', 'Пользователь уже зарегистрирован');
        break;
      case 'Invalid promocode':
        errors.promocode = i18n.t('backend-errors.auth.invalid-promocode', 'Пользователь уже зарегистрирован') ?? detail;
        break;
      default:
        errors.common = detail;
    }
  }

  if (Array.isArray(detail)) {
    detail.forEach((err) => {
      const location = err.loc?.[1];
      if (!err.msg) { return; }
      const message = errorsTranslates[err.msg] || err.msg;

      if ((Object.keys(errors)).includes(location)) {
        errors[location as keyof RegisterEmailErrors] = message;
      } else {
        errors.common = message;
      }
    });
  }

  return errors;
};

export const handleRegisterTGErrors = (status: number, data: BackendError): RegisterTelegramErrors => {
  const { detail } = data;
  const errors: RegisterTelegramErrors = { ...initialRegisterTelegramError };

  if (typeof detail === 'string') {
    switch (detail) {
      case 'Invalid promocode':
        errors.promocode = i18n.t('backend-errors.auth.invalid-promocode', 'Пользователь уже зарегистрирован') ?? detail;
        break;
      default:
        errors.common = detail;
    }
  }

  if (Array.isArray(detail)) {
    detail.forEach((err) => {
      const location = err.loc?.[1];
      if (!err.msg) { return; }
      const message = errorsTranslates[err.msg] || err.msg;

      if ((Object.keys(errors)).includes(location)) {
        errors[location as keyof RegisterTelegramErrors] = message;
      } else {
        errors.common = message;
      }
    });
  }

  return errors;
};

const translatePhoneRegError: Record<string, string> = {
  'Invalid symbols in phone': i18n.t('backend-errors.auth.invalid-symbols-in-phone', 'Неправильные формат номера'),
  'User with this phone exists': i18n.t('backend-errors.auth.user-with-this-phone-exists', 'Пользователь с таким номером уже существует'),
};

export const handleRegisterPhoneError = (status: number, data: BackendError): RegisterPhoneErrors => {
  const { detail } = data;
  const errors: RegisterPhoneErrors = { ...initialPhoneRegError };

  if (typeof detail === 'string') {
    switch (detail) {
      case 'User with this phone exists':
        errors.phone = translatePhoneRegError[detail] ?? detail;
        break;
      case 'Invalid promocode':
        errors.promocode = i18n.t('backend-errors.auth.invalid-promocode', 'Пользователь уже зарегистрирован') ?? detail;
        break;
      default:
        errors.common = detail;
    }
  }

  if (Array.isArray(detail)) {
    detail.forEach((err) => {
      const location = err.loc?.[1];
      if (!err.msg) { return; }
      const message = translatePhoneRegError[err.msg] || err.msg;

      if ((Object.keys(errors)).includes(location)) {
        errors[location as keyof RegisterPhoneErrors] = message;
      } else {
        errors.common = message;
      }
    });
  }

  return errors;
};

export const transformPasswordSmsVerify = (respons: PasswordResetVerifyRespons): string => respons.data.password_reset_id;
export const detectPhoneZero = (phone: string): string => phone[3] === '0' ? `255${phone.slice(4)}` : phone;
