import { Link } from 'react-router-dom';

import { useAppSelector } from 'hooks';
import { selectUserGeo } from 'store/user/selectors';
import Flag from 'components/shared/Flag/Flag';
import { bemCn } from 'utils/bem-cn';

// import logotype from './assets/logotype@2x.png';
import bookebetLogo from './assets/BOOKEEBET-LOGO-white-version.png';

import './Logotype.scss';

type Props = {
  className?: string;
}

const Logotype = (props: Props) => {
  const { country } = useAppSelector(selectUserGeo);

  const b = bemCn('logotype');
  return (
    <div className={b(null, props.className)}>
      <Link className={b('link')} to="/home" >
        {/* <p className={b('text')}>{logoText}</p> */}
        <img className={b('image')}
          src={bookebetLogo}
          width={90}
          height={30}
          alt="BOOKEBET"
        />
        {!!country && (
          <Flag className={b('flag-icon')}
            countryCode={country}
            height={15} width={15}
          />
        )}
      </Link>
    </div>
  );
};

export default Logotype;
