import i18n from 'i18n/i18n';

import type { Currency, CurrencyTitles } from 'types/wallets-data';

export const currenciesTitles: Record<Currency, CurrencyTitles> = {
  EUR: {
    title: i18n.t('currency-title.EUR', 'Евро (EUR)'),
    symbol: '€',
  },
  USD: {
    title: i18n.t('currency-title.USD', 'Доллар США (USD)'),
    symbol: '$',
  },
  RUB: {
    title: i18n.t('currency-title.RUB', 'Российский рубль (RUB)'),
    symbol: '₽',
  },
  KZT: {
    title: i18n.t('currency-title.KZT', 'Казахстанский тенге (KZT)'),
    symbol: '₸',
  },
  UAH: {
    title: i18n.t('currency-title.UAH', 'Украинская гривна (UAH)'),
    symbol: '₴',
  },
  TJS: {
    title: i18n.t('currency-title.TJS', 'Таджикский сомони (TJS)'),
    symbol: 'с',
  },
  BTC: {
    title: i18n.t('currency-title.BTC', 'Биткойн (BTC)'),
    symbol: '₿',
  },
  UZS: {
    title: i18n.t('currency-title.UZS', 'Узбекский сум (UZS)'),
    symbol: 'UZS',
  },
  KGS: {
    title: i18n.t('currency-title.KGS', 'Киргизский сом (KGS)'),
    symbol: 'с',
  },
  TMT: {
    title: i18n.t('currency-title.TMT', 'Туркменский манат (TMT)'),
    symbol: 'm',
  },
  AZN: {
    title: i18n.t('currency-title.AZN', 'Азербайджанский манат (AZN)'),
    symbol: '₼ ',
  },
  AMD: {
    title: i18n.t('currency-title.AMD', 'Армянский драм (AMD)'),
    symbol: '֏',
  },
  BYN: {
    title: i18n.t('currency-title.BYN', 'Белорусский рубль (BYN)'),
    symbol: 'Br',
  },
  MDL: {
    title: i18n.t('currency-title.MDL', 'Молдавский лей (MDL)'),
    symbol: 'L',
  },
  USDT: {
    title: i18n.t('currency-title.USDT', 'Tether (USDT)'),
    symbol: '₮',
  },
  BUSD: {
    title: i18n.t('currency-title.BUSD', 'Binance USD (BUSD)'),
    symbol: '₿',
  },
  BNB: {
    title: i18n.t('currency-title.BNB', 'Binance Coin (BNB)'),
    symbol: 'BNB',
  },
  ETH: {
    title: i18n.t('currency-title.ETH', 'Ethereum (ETH)'),
    symbol: 'Ξ',
  },
  // ! добавить GEL в файлы переводов
  GEL: {
    title: i18n.t('currency-title.GEL', 'Грузинский лари (GEL)'),
    symbol: '₾',
  },
  KES: {
    title: i18n.t('currency-title.KES', 'Kenya Shilling (KES)'),
    symbol: 'KSh'
  },
  TZS: {
    title: 'Tanzanian Shilling',
    symbol: 'TSh'
  }
};

export const getCurrencyTitle = (currency?: Currency | null): string => {
  if (!currency) {
    return i18n.t('currency-title.default', 'Валюта');
  }
  return currenciesTitles[currency]?.title ?? currency;
};

export const getCurrencyTitleWithoutAbbreviation = (currency?: Currency | null): string => {
  if (!currency) {
    return i18n.t('currency-title.default', 'Валюта');
  }
  return currenciesTitles[currency]?.title.split('(')[0] ?? currency;
};

export const getCurrencySymbol = (currency?: Currency | null): string => {
  if (!currency) {
    return '';
  }
  return currenciesTitles[currency]?.symbol ?? '*';
};
