export const copyText = (value: string) => {
  const textarea = document.createElement('input');
  textarea.id = 'temp';
  textarea.style.height = '0px';
  document.body.appendChild(textarea);
  const selector: HTMLTextAreaElement | null = document.querySelector('#temp');
  if (selector !== null) {
    selector.value = value;
    selector.select();
  }
  document.execCommand('copy');
  document.body.removeChild(textarea);
};
